import React from 'react'
import styled from 'styled-components'
import moment from 'moment';


const Timeline = styled.div`
  position: relative;
  min-height: 3.12rem;
  padding: 1.25rem 0;

  .timeline-time {
    position: absolute;
    left: 0;
    width: 18%;
    text-align: left;
    top: 1.87rem;
    .date {
      line-height: 1rem;
      font-size: 0.9rem;
      display: block;
      font-weight: 600;
      color: #9DB9D6;
    }
    .year {
      line-height: 1.4rem;
      font-size: 1.1rem;
      font-weight: 700;
      color: #9DB9D6;
      opacity: 0.7;

    }
  }
  .timeline-icon {
    left: 5.91%;
    position: absolute;
    width: 10%;
    text-align: center;
    top: 2.5rem;
    @media (max-width: 700px) {
      display: none;
    }
    a {
      text-decoration: none;
      width: 1.25rem;
      height: 1.25rem;
      display: inline-block;
      border-radius: 1.25rem;
      background: #f7f8fa;
      line-height: 1.1rem;
      color: #fff;
      font-size: 0.9rem;
      border: 3px solid #9DB9D6;
      transition: border-color .2s linear;
    }
  }
  .timeline-body {
    margin-left: 16%;
    with: 84%;
    max-width: 84%;
    ${'' /* overflow: hidden; */}
    position: relative;
    @media (max-width: 700px) {
      display: none;
      margin-left: 0;
    }
  }

  .item__box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0.3rem;
    background: white;
    box-sizing: border-box;
    margin-bottom: 2rem;
    border: 1px solid #CBD5E0;
    @media (max-width: 700px) {
      margin-bottom: 0;
    }
    .card {
      width: 100%;
      .card__container {
        width: 100%;
        height: 100%;
        background: white;
        border-radius: 1rem;
        position: relative;
        .item__box-header {
          padding: 3.8rem 1rem 1rem;
          background: #F8FBFF;
          border-radius: 0.32rem 0.32rem 0 0;
          line-height: 1rem;
          font-size: 0.75rem;
          color: #718096;
          display: flex;
          flex-direction: column;
          width: 100%;
          .left {
            flex: 1 1 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.4rem;
            .company {
              color: #1DAFB6;
              text-transform: uppercase;
              margin-bottom: 0.5rem;
            }
            .title {
              color: #1A212D;
            }
          }
          .right {
            flex: 1 1 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .location {
              margin-bottom: 0.7rem;
            }
          }
          @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
            flex-direction: row;
            .left {
              flex: 1 1 50%;
            }
            .right {
              flex: 1 1 50%;
              align-items: flex-end;
            }
          }
        }
      }
      &:before{
        position: absolute;
        top: 1.7rem;
        left:-0.7rem;
        content: '';
        background: ${props =>props.color};
        height: 28px;
        width: 28px;
        transform : rotate(45deg);
      }

      &:after{
        position: absolute;
        content: attr(data-label);
        top: 10px;
        left: -15px;
        padding: 0.5rem;
        width: 15rem;
        background: ${props =>props.color};
        color: white;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
        text-transform: uppercase;
        letter-spacing: 1px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .item__box-footer {
      border-radius: 0 0 0.32rem 0.32rem;
      padding: 1rem;
      line-height: 1rem;
      font-size: 0.8rem;
      color: #183D63;
      border-top: 1px solid #CBD5E0;
      width: 100%;
      white-space: pre-wrap;
    }
  }
  .skills {
    width: 100%;
    padding: 1rem;
    border-top: 1px solid #CBD5E0;
    border-radius: 0;
  }
  .languages {
    border-top: 1px solid #CBD5E0;
    display: flex;
    width: 100%;
    border-radius: 0 0 0.32rem 0.32rem;
    .left {
      border-right: 1px solid #CBD5E0;
      padding: 1rem;
      border-radius: 0 0 0 0.32rem;
      flex: 1 1 50%;
    }
    .right {
      padding: 1rem;
      border-radius: 0 0  0.32rem 0;      
      flex: 1 1 50%;
    }
  }

  .label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    line-height: 1.1rem;
    color: #138388;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .skills.tasks {
    ul {
      list-style: disc;
      margin-left: 1rem;
      padding: 0;
      display: block;
    }
  }
`

const validateDate = date => {
  if (moment(date).format('DD/MM/YYYY') !== 'Invalid date') {
    return moment(date).format('MMM. YYYY')
  } else {
    return '';
  };
}

const Experience = (props) => {  


  const { experience } = props;

  const title = experience?.title;
  const company = experience?.company;
  const startDate = experience?.date_start; 
  const endDate = experience?.date_end; 
  const location = experience?.location?.text;
  const description = experience?.description;

  return (
    <Timeline color="#22B5F3">
      {startDate && (
        <div className="timeline-time">
          <span className="date">{moment(startDate).format('DD MMM')}</span>
          <span className="year">{moment(startDate).year()}</span>
        </div>
      )}
      <div className="timeline-icon">
          <a href="javascript:;">&nbsp;</a>
      </div>
      <div className="item__box timeline-body">
        <div className="card" data-label="Predicted">
          <div class="card__container">
            <div className="item__box-header">
              <div className="left">
                <span className="company">{company || '_'}</span>
                <span className="title">{title || '_'}</span>
              </div>
              <div className="right">
                {location && (
                  <span className="location">
                    {location}
                  </span>
                )}
                <span className="date">
                  {validateDate(startDate) ? 'From' + ' ' + validateDate(startDate) : ''}
                  {validateDate(endDate) ? ' ' + 'to' + ' ' + validateDate(endDate) : ''}

                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="item__box-footer">
          {description}
        </div>
      </div>
    </Timeline>
  )
}

export default Experience