import React from "react"
import styled from "styled-components"
import Form from "./form"
import Result from "./result"

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 52.31rem;
  }
`


const Unfolding = (props) => {
  return (
    <StyledContainer
      color="#22B5F3"
    >
      <Form
        postProfileUnfolding={props.postProfileUnfolding}
        response={props.response}
        profileUnfolding={props.profileUnfolding}
        params={props.queryParams}
      />
      {props.queryParams?.key ?
        (
          <Result 
            getProfileUnfolding={props.getProfileUnfolding}
            profileUnfolding={props.profileUnfolding}
            profileKey={props.queryParams.key}
            email={props.queryParams.email}
          />
        )
        :
        (
          ''
        )
      }
    </StyledContainer>
  )
}


export default Unfolding;