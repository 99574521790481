import React from "react"
import styled from "styled-components"
import Experiences from './experiences'
import Educations from './educations'

const StyledContainer = styled.form`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  line-height: 1rem;
  font-size: 0.75rem;
  color: #718096
`

const StyledSectionTitle = styled.div`
  color: #1A212D;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.5rem;
`

const StyledProfileBody = styled.div`
  width: 100%;
  overflow: auto;
  .label {
    font-weight: bold;
    margin: 1rem 0;
    font-size: 0.95rem;
    line-height: 1.1rem;
    color: #138388;
  }
`
const Unfolding = ({ profile }) => {
  return (
    <StyledContainer color="#22B5F3">
      <StyledSectionTitle>Result</StyledSectionTitle>
      <StyledProfileBody>
        <div className="label">
          Future Experiences
        </div>
        <Experiences experiences={profile.experiences}/>
        {profile.educations && 
          <div className="label">
            Future Educations
          </div>
        }
        {profile.educations && <Educations educations={profile.educations}/>}
      </StyledProfileBody>
    </StyledContainer>
  )
}


export default Unfolding;
