import React, {useState, useEffect, useCallback} from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import {useDropzone} from 'react-dropzone'

import Input from "components/common/input"
import ArrowRight from "components/icons/arrowRight"
import Sync from "components/icons/sync"
import Envolope from "components/icons/envolope"
import CloudArrowUp from "components/icons/cloudArrowUp";
import ErrorMessage from "components/common/errorMessage"
import StyledBtn from "styles/button"


const StyledForm = styled.form`
  background: white;
  border-radius: 0.62rem;
  border-top: 0.3125rem solid ${props =>props.color};
  min-height: 23rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
  .form__body {
    padding: 1.5rem;
    .control {
      margin-bottom: 1rem;
    }
    .label {
      color: ${({ theme }) => theme.colors.zodiacLight};
      font-size: 1.25rem;
      line-height: 2rem;
      margin-bottom: 0.55rem;
    }

    .subtitle {
      color: ${({ theme }) => theme.colors.tertiary};
      font-size: 0.9rem;
      line-height: 1.5rem;
      margin-bottom: 1.2rem;
      background: #F9FCFF;
    }
    .alert {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 3rem;
      text-align: center;
      .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        a:first-of-type {
          margin-right: 0;
          margin-bottom: 1rem;
        }
        a {
          width: 100%;
        }
        margin-top: 0.7rem;
      }
      .logo {
        height: 3.56rem;
        width: 3.56rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E7F0FF;
        border-radius: 0.22rem;
        margin-bottom: 1rem;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        .buttons {
          flex-direction: row;
          a:first-of-type {
            margin-right: 1rem;
            margin-bottom: 0;
          }
          a {
            width: unset;
          }
        }
      }
    }
  }
  .form__footer {
    padding: 1.3rem 1.5rem;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #EBEFF9;
  }
`

const StyledButton = styled(StyledBtn).attrs({
  as: "button"
})`
  padding: 0 1.3rem;
  height: 3.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 0.375rem;
  background: #22B5F3;
  border: none;
  cursor: pointer;
  min-width: 10rem;
`

const StyledButtonLink = styled(StyledBtn)`
  padding: 0 1.3rem;
  height: 3.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 0.375rem;
  background: #22B5F3;
  border: none;
  cursor: pointer;
`

const StyledButtonLinkWhite = styled(StyledButtonLink)`
  color: #22B5F3;
  background: white;
  border: 1px solid #22B5F3;
`

const DivBox = styled.div`
  border: 1.2px dashed #9DB9D6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  align-items: center;
  min-height: 10rem;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
  background: #FFF;
`
const DropzoneTextDescription = styled.div`
  font-size: 1rem;
  color: #9DB9D6;
  margin-top: 1rem;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  border-radius: 50%;
  background: #FFF;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  transition: transform 0.15s linear;
  &:hover {
      transform: scale(1.1);
      transition: transform 0.15s linear;
      }
  `
const ShadowBox = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 50%;
  padding: .5rem;
  background: #fafafa;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
 
`

const FileInfoDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  span {
    margin: 0.5rem;
  }
`
const Form = props => {
  const [step, setStep] = useState(1)
  const [email, setEmail] = useState("")
  const [profileFile, setProfileFile] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false);

  const dControls = useAnimation()
  const eControls = useAnimation()
  const rControls = useAnimation()
  useEffect(() => {
    const StepTransitionSequence = async () => {
        await dControls.start({
          opacity: 1,
          x: 0,
          transition: { delay: 0.1 },
        })
        await eControls.start({
          opacity: 1,
          x: 0,
          transition: { delay: 0.1 },
        })
        await rControls.start({
          opacity: 1,
          x: 0,
          transition: { delay: 0.1 },
        })
        // Animate underlining to hover state
    }
    StepTransitionSequence()
  }, [eControls, dControls, rControls, step])

  useEffect(() => {
    if(!firstLoad) {
      if(props.response?.s) {
        setStep(3)
        setError(false)
      }
      if(props.response.f) {
        setStep(3)
        setError(true)
      }
    }
    return () => {
      setFirstLoad(false)
    };
  }, [props.response])
  
  useEffect(() => {
    setStep(1)
  }, [])

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
    // Do whatever you want with the file contents
        const binaryStr = reader.result
        console.log(binaryStr)
    }
    reader.readAsArrayBuffer(file)

    setProfileFile(file)
    })
  }, [])

  const {getRootProps, getInputProps} = useDropzone({onDrop})
  const addProfileFile = e => {
    e.preventDefault()
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute("6Lcbn24bAAAAADaaYP7VaaG6FYlzzFT0sAZUvqjg", { action: 'submit' }).then(token => {
        if(email) {
          submitData(token);
        } else {
          setLoading(false);
        }
      });
    });
  }

  const submitData = token => {
    // call a backend API to verify reCAPTCHA response
    fetch('https://api-workflows.hrflow.ai/teams/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/92a6071d499f12b0d37c3f33c3ae9acca8f71955', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "g-recaptcha-response": token
      })
    }).then(res => res.json()).then(res => {
      const jsonResponse = JSON.parse(res.stdout)
      if(jsonResponse.success && jsonResponse.score >= 0.7) {
        props.postProfileUnfolding({
          file: profileFile, 
          file_name: profileFile.name,
          file_size: profileFile.size,
          email: email, 
          marketing_data: props.params, 
          referrer: document.referrer
      })
      } else {
        setError(true)
      }
      setLoading(false);
    });
  }

  return (
    <StyledForm
      color="#22B5F3"
      onSubmit={e => {
          e.preventDefault();
          if(step === 1) {
            setStep(2)
          } else {
            addProfileFile(e)
          }
        }
      }
    >
      <div className="form__body">
        { step === 1 && (
          <motion.div
            className="control"
            initial={{ opacity: 0, x: 80 }}
            animate={dControls}
          >
            <div className="label">Upload resume</div>
            <div className="subtitle">
              Upload a resume/CV extract its semantic entities — such as names, emails, phones, locations, companies, schools, degrees, job titles, tasks, skills, interests, durations, dates and more.
            </div>
            <DivBox {...getRootProps()}>
              <input {...getInputProps()} />
              <ShadowBox>
                <IconWrapper>
                  <CloudArrowUp color="#9DB9D6" width="30" height="30" />
                </IconWrapper>
              </ShadowBox>
              <DropzoneTextDescription>
                Drag and drop your resume here or <span style={{ color: "#60a5fa"}}>click</span> to upload your resume.
              </DropzoneTextDescription>
              {profileFile && (
                <FileInfoDiv>
                  <span>{profileFile.name}</span>
                  <span>{(profileFile.size/1024).toFixed(2) + 'Kb'}</span>
                </FileInfoDiv>
              )}
              {props.profileUnfolding && props.profileUnfolding.s && props.params.file_name && props.params.file_size && (
                <FileInfoDiv>
                  <span>{props.params.file_name}</span>
                  <span>{(props.params.file_size/1024).toFixed(2) + 'Kb'}</span>
                </FileInfoDiv>
              )}
            </DivBox>
          </motion.div>
        )}
        {step === 2 && (
          <motion.div
            className="control"
            initial={{ opacity: 0, x: 80 }}
            animate={eControls}
          >
            <div className="label">Enter your work email</div>
            <div className="subtitle">
              In order to complete your profile unfolding task, please enter your work email.
            </div>
            <Input 
              changed={event => setEmail(event.target.value)}
              type="input"
              placeholder="Enter your email"
            />
          </motion.div>
        )}
        {step === 3 && (
          <motion.div
            className="alert"
            initial={{ opacity: 0, x: 80 }}
            animate={rControls}
          >
            { error ?
              (
                <ErrorMessage
                  title="Something went wrong"
                  subtitle="Please retry again later."
                  type="error"
                  min="true"
                />
              )
              :
              (
                <>
                  <div className="logo">
                    <Envolope color="#2A7CF8" />
                  </div>
                  <div className="label">We sent your profile parsing results on your email adress</div>
                  <div className="subtitle">
                    Please click on the link in the email to access your results
                  </div>
                </>
              )
            }
            <div className="buttons">
              <StyledButtonLinkWhite
                to="/profile-analysis/unfolding/"
                onClick={() => setStep(1)}
              >
                <span className="mr-1">Try another resume</span><Sync color="#22B5F3"/>
              </StyledButtonLinkWhite>
              <StyledButtonLink
                  to="https://hrflow.ai/signup"
                  target="_blank"
                  rel="noreferrer"
              >
                <span className="mr-1">Register now</span><ArrowRight color="#FFF" />
              </StyledButtonLink>
            </div>
          </motion.div>
        )}
      </div>
      { step !== 3 && (
          <div className="form__footer">
            <StyledButton
                disabled={props.response.r || loading || (step === 1 && !profileFile)}
                loading={props.response.r || loading}
            >
              { (props.response.r || loading) ?
                (
                  <div style={{display: 'flex', justifyContent: 'center', width:"100%"}}>
                    <span className="loader"></span>
                  </div>
                )
                :
                (
                  <>
                    <span className="mr-1">Analyze</span><ArrowRight color="#FFF" />
                  </>
                )
              }
            </StyledButton>
          </div>
        )
      }
    </StyledForm>
  )
}


export default Form
